import React, { useState } from "react";
import styles from "./SearchPolisies.module.css";
import { useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { API_URL } from "../../Сonstants";
// components
import DataPolisies from "../DataPolisies/DataPolisies";
// img
import close from "../../image/Закрыть.svg";
const SearchPolisies = ({ closeModal }) => {
  const [data, setData] = useState([]);
  const [modalActive, setModalActive] = useState(false);

  //  useForm
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    mode: "onChange",
  });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  // get
  const search = useWatch({
    control,
    name: "search",
  });

  const onSubmit = (data) => {
    setDisabled(true);
    setLoading(true);
    data.car_type = parseInt(data.car_type);

    var getMyHeaders = new Headers();

    getMyHeaders.append("Content-Type", "application/json");

    var getRequestOptions = {
      method: "GET",
      headers: getMyHeaders,
    };
    try {
      fetch(`${API_URL}/car_confirm?client_code=${search}`, getRequestOptions)
        .then((res) => {
          if (res?.status >= 400) {
            setLoading(false);
            setDisabled(false);
            return toast.error("Полис не найден");
          } else {
            setModalActive(!modalActive);
            // closeModal()
            setLoading(false);
            setDisabled(false);
          }
          return res.json();
        })
        .then((data) => {
          setData(data);

          setLoading(false);
          setDisabled(false);
        })
        .catch((err) => {
          toast.error("Форма не отправлена");
          setLoading(false);
          setDisabled(false);
        })
        .finally(() => {
          setLoading(false);
          setDisabled(false);
        });
    } catch (e) {
      toast.error("Ошибка с сервером");
      setLoading(false);
      setDisabled(false);
    }
  };


  const checkIfNumber = (event) => {
    if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "Tab", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key) &&
      !(event.ctrlKey && event.key === "v") // Разрешает Ctrl+V
    ) {
      event.preventDefault();
    }
  };
  
  return (
    <div className={styles.window}>
      <div className="wrapper">
        <div className={styles.polis}>
          <div className={styles.windowHead}>
            <img onClick={closeModal} src={close} alt="close" />
          </div>
          <div className={styles.headText}>
            <h1>Введите полис</h1>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.polisBlock}>
              {/* <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                }}
                style={
                  errors?.search
                    ? { marginBottom: "10px" }
                    : { marginBottom: "0px" }
                }
                onKeyDown={(event) => checkIfNumber(event)}
                maxLength={6}
                // type="number"
                type="text"
                placeholder="000000"
                {...register("search", {
                  required: "Поле обязательно к заполнению",
                  minLength: {
                    value: 6,
                    message: "Введите 6 цифр",
                  },
                  maxLength: {
                    value: 6,
                    message: "Неккоректный номер полиса",
                  },
                  pattern: {
                    value: /[0-9]/,
                  },
                })}
              /> */}
              <input
  onInput={(e) => {
    // Очищаем от нецифровых символов при вставке
    e.target.value = e.target.value.replace(/\D/g, "");

    // Обрезаем до 6 символов, если вставили больше
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  }}
  style={errors?.search ? { marginBottom: "10px" } : { marginBottom: "0px" }}
  onKeyDown={(event) => {
    // Разрешаем только цифры и служебные клавиши
    if (
      !/[0-9]/.test(event.key) &&
      !["Backspace", "Tab", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key) &&
      !(event.ctrlKey && event.key === "v") // Разрешаем Ctrl+V
    ) {
      event.preventDefault();
    }
  }}
  onPaste={(e) => {
    let paste = e.clipboardData.getData("text");
    if (!/^\d{1,6}$/.test(paste)) {
      e.preventDefault();
    }
  }}
  maxLength={6}
  type="text"
  placeholder="000000"
  {...register("search", {
    required: "Поле обязательно к заполнению",
    minLength: {
      value: 6,
      message: "Введите 6 цифр",
    },
    maxLength: {
      value: 6,
      message: "Некорректный номер полиса",
    },
    pattern: {
      value: /^[0-9]{6}$/, // Только 6 цифр
      message: "Введите ровно 6 цифр",
    },
  })}
/>

              <div>
                {errors?.search && (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "15px",
                    }}
                  >
                    {errors?.search?.message || "Такого логина не существует"}
                  </p>
                )}
              </div>
              {loading ? (
                <span className="loading"></span>
              ) : (
                <button
                  type="submit"
                  disabled={!isValid || disabled}
                  style={
                    disabled || !isValid
                      ? { opacity: "0.5", cursor: "default" }
                      : { opacity: "1", cursor: "pointer" }
                  }
                >
                  Проверить полис
                </button>
              )}
            </div>
          </form>
        </div>

        {modalActive ? (
          <DataPolisies data={data} closeForm={() => setModalActive(false)} />
        ) : null}
      </div>
    </div>
  );
};

export default SearchPolisies;
