/* eslint-disable no-sequences */
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import styles from "./TypeTransport.module.css";

function TypeTransportForm({ handleInput }) {
  // useForm
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext({
    mode: "onChange",
  });

  // firefox
  const checkIfNumber = (event) => {
    const regex = new RegExp(
      /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
    );
    return !event.key.match(regex) && event.preventDefault();
  };
  // typeCar
  const type = [
    {
      id: 0,
      value: 1,
      radio: "Легковой",
    },
    {
      id: 1,
      value: 2,
      radio: "Электромобиль",
    },
    {
      id: 2,
      value: 3,
      radio: "Грузовое авто",
    },
    {
      id: 3,
      value: 4,
      radio: "Автобус",
    },
    {
      id: 4,
      value: 5,
      radio: "Мотоцикл",
    },
    {
      id: 5,
      value: 6,
      radio: "Троллейбусы",
    },
    {
      id: 6,
      value: 7,
      radio: "Рабочие техники",
    },
  ];
  const carType = watch("car_type");
  const [selectedType, setSelectedType] = useState(
    carType ? Number(carType) : type[0].value
  );
  const handleClick = (value) => {
    setSelectedType(value);
  };

  return (
    <div className={styles.form}>
      <div className={styles.transport}>
        <div className={styles.headText}>
          <h1>Тип транспорта:</h1>
        </div>

        <div className={styles.formBlock}>
          <div className={styles.radioBlock}>
            {type.map((data, i) => (
              <div onClick={() => handleClick(data.value)} key={i}>
                <input
                  {...register("car_type", {
                    required: "Поле обязательно к заполнению",
                  })}
                  id={data.id}
                  type="radio"
                  value={data.value}
                  checked={selectedType === data.value}
                />
                <label htmlFor={data.id}>{data.radio}</label>
              </div>
            ))}
          </div>

          <div>
            {errors?.car_type && (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {errors?.car_type?.message || "Ошибка"}
              </p>
            )}
          </div>
          <div className={styles.inputBlock}>
            <div className={styles.box}>
              <label htmlFor="car_model">Марка и модель</label>
              <input
                id={styles.car_model}
                type="text"
                placeholder="Укажите"
                {...register("car_model", {
                  required: "Поле обязательно к заполнению",
                })}
              />

              <div>
                {errors?.car_model && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {errors?.car_model?.message || "Ошибка"}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.box}>
              <label htmlFor="car_registration_mark">Гос.номер</label>
              <input
                id={styles.car_registration_mark}
                type="text"
                placeholder="хх 000 хх"
                onInput={handleInput}
                {...register("car_registration_mark", {
                  required: "Поле обязательно к заполнению",
                })}
              />

              <div>
                {errors?.car_registration_mark && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {errors?.car_registration_mark?.message || "Ошибка"}
                  </p>
                )}
              </div>
            </div>
            {selectedType === 1 && (
              <div className={styles.box}>
                <label htmlFor="car_engine_capacity">Объем двигателя см3</label>
                <input
                  onKeyDown={(event) => checkIfNumber(event)}
                  id={styles.car_engine_capacity}
                  type="number"
                  placeholder="0000"
                  {...register("car_engine_capacity", {
                    required: "Поле обязательно к заполнению",
                  })}
                />

                <div>
                  {errors?.car_engine_capacity && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.car_engine_capacity?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            )}
            {selectedType === 2 && (
              <div className={styles.box}>
                <label htmlFor="motor_power">
                  Мощность электродвигателя (кВт)
                </label>
                <input
                  onKeyDown={(event) => checkIfNumber(event)}
                  id={styles.motor_power}
                  type="number"
                  placeholder="0000"
                  {...register("motor_power", {
                    required: "Поле обязательно к заполнению",
                  })}
                />

                <div>
                  {errors?.motor_power && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.motor_power?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            )}
            {selectedType === 3 && (
              <div className={styles.box}>
                <label htmlFor="car_maximum_mass">
                  Масса грузового авто (тонн)
                </label>
                <input
                  onKeyDown={(event) => checkIfNumber(event)}
                  id={styles.car_maximum_mass}
                  type="number"
                  placeholder="0000"
                  {...register("car_maximum_mass", {
                    required: "Поле обязательно к заполнению",
                  })}
                />

                <div>
                  {errors?.car_maximum_mass && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.car_maximum_mass?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            )}
            {selectedType === 4 && (
              <div className={styles.box}>
                <label htmlFor="car_seats_count">
                  Число пассажирских мест автобуса
                </label>
                <input
                  onKeyDown={(event) => checkIfNumber(event)}
                  id={styles.car_seats_count}
                  type="number"
                  placeholder="0000"
                  {...register("car_seats_count", {
                    required: "Поле обязательно к заполнению",
                  })}
                />

                <div>
                  {errors?.car_seats_count && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {errors?.car_seats_count?.message || "Ошибка"}
                    </p>
                  )}
                </div>
              </div>
            )}

            <div className={styles.box}>
              <label htmlFor="car_vin">Номер кузова (VIN)</label>
              <input
                onInput={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                id={styles.car_vin}
                type="text"
                placeholder="0хххх00хххх000000"
                {...register("car_vin", {
                  required: "Поле обязательно к заполнению",
                  // pattern: {
                  //   value: /^[a-zA-Z0-9][a-zA-Z0-9]*$/,
                  //   message: "Введите на латинице",
                  // },
                })}
              />

              <div>
                {errors?.car_vin && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {errors?.car_vin?.message || "Ошибка"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypeTransportForm;
