import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styles from "./TechCertificate.module.css";
const TechCertificateForm = ({ handleInput }) => {
  // useForm
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext({
    mode: "onChange",
  });

  // startDate
  const todayDate = new Date().toISOString().substr(0, 10);
  // endDate
  const yearDate = new Date(
    new Date().getFullYear() + 1,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toISOString()
    .substr(0, 10);

  const vehicle_start_date = useWatch({
    control,
    name: "vehicle_start_date",
  });
  const vehicle_end_date = useWatch({
    control,
    name: "vehicle_end_date",
  });

  const validateEndDate = () => {
    const startDate = new Date(vehicle_start_date);
    const endDateValue = new Date(vehicle_end_date);
    const daysDiff = (endDateValue - startDate) / (1000 * 3600 * 24);

    if (daysDiff < 5 || daysDiff > 365 || endDateValue <= startDate) {
      return "Дата окончания должна быть не менее чем на 5 дней и не более чем на 1 год больше даты начала";
    }
    return true;
  };

  return (
    <div className={styles.form}>
      <div className={styles.certificate}>
        <div className={styles.headText}>
          <h1>Техпаспорт:</h1>
        </div>

        <div className={styles.inputBlock}>
          <div className={styles.box}>
            <label htmlFor="vehicle_passport">Номер техпаспорта</label>
            <input
              id={styles.vehicle_passport}
              type="text"
              placeholder="№  -  0000000"
              onInput={handleInput}
              {...register("vehicle_passport", {
                required: "Поле обязательно к заполнению",
              })}
            />
            <div>
              {errors?.vehicle_passport && (
                <p
                  style={{
                    color: "red",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {errors?.vehicle_passport?.message || "Ошибка"}
                </p>
              )}
            </div>
          </div>
          <div className={styles.box}>
            <label htmlFor="vehicle_start_date">Дата начала страхования</label>
            <input
              id={styles.vehicle_start_date}
              type="date"
              defaultValue={todayDate}
              {...register("vehicle_start_date", {
                required: "Поле обязательно к заполнению",
              })}
            />

            {/* <div>
                {errors?.vehicle_start_date && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {errors?.vehicle_start_date?.message || "Ошибка"}
                  </p>
                )}
              </div> */}
          </div>
          <div className={styles.box}>
            <label htmlFor="vehicle_end_date">Дата окончания страхования</label>

            <input
              id={styles.vehicle_end_date}
              type="date"
              defaultValue={yearDate}
              {...register("vehicle_end_date", {
                required: "Поле обязательно к заполнению",
                validate: validateEndDate,
              })}
            />

            <div>
              {errors?.vehicle_end_date && (
                <p
                  style={{
                    color: "red",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {errors?.vehicle_end_date?.message || "Ошибка"}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={styles.radioContent}>
          <div className={styles.radioBlock}>
            <h1>Наличие карты техосмотра:</h1>
            <div className={styles.radioBox}>
              <div>
                <input
                  {...register("is_diagnostic_card_exist", {
                    // required: "Поле обязательно к заполнению",
                  })}
                  id={"yes"}
                  type="radio"
                  value={1} // Значение true как булев тип
                />
                <label htmlFor={"yes"}>Есть</label>
              </div>
              <div>
                <input
                  {...register("is_diagnostic_card_exist", {
                    // required: "Поле обязательно к заполнению",
                  })}
                  id={"no"}
                  type="radio"
                  value={0} // Значение false как булев тип
                />
                <label htmlFor={"no"}>Нет</label>
              </div>
            </div>
            <div>
              {errors?.is_diagnostic_card_exist && (
                <p
                  style={{
                    color: "red",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {errors?.is_diagnostic_card_exist?.message || "Ошибка"}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechCertificateForm;
